<template>
  <div class="hero-container">
    <div class="content">
      <div class="title-with-logo">
        <!-- Logo -->
        <div class="logo-container">
          <img :src="logoSource" alt="Logo" />
        </div>
        <h1>Let Your Windows Shine Like Never Before</h1>
      </div>
      <h2>Your Trusted Window Cleaning Experts</h2>
      <p>
        We don't just clean windows, we transform spaces. Bring a fresh new look to your surroundings with
        our expert cleaning services. Let us help you shine!
      </p>
      <footer>
        <p><strong>For quotes:</strong></p>
        <p>Email us at: <a href="mailto:admin@gawlerwindowcleaning.com.au">admin@gawlerwindowcleaning.com.au</a></p>
        <p>Or call us at: <strong>0493 195 905</strong></p>
      </footer>
    </div>
  </div>
</template>

<script>
import logoGif from "@/assets/logo.gif";
import logoJpeg from "@/assets/logo.jpeg";

export default {
  name: "HeroSection",
  data() {
    return {
      logoSource: logoGif, // Imagen por defecto
    };
  },
  mounted() {
    // Detectar dispositivo móvil
    if (window.matchMedia("(max-width: 768px)").matches) {
      this.logoSource = logoJpeg; // Cambiar a la versión JPEG en dispositivos móviles
    }
  },
};
</script>

<style scoped>
.hero-container {
  background: linear-gradient(135deg, #3f94c3, #8fd8ff);
  border-radius: 15px;
  color: #3f94c3;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.content {
  max-width: 900px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.5s ease-in-out;
}

.title-with-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.logo-container img {
  width: 150px;
  height: auto;
}

h1 {
  font-size: 3rem;
  color: #3f94c3;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 20px;
  color: #00796b;
}

p {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

footer {
  font-size: 1rem;
  color: #00796b;
  margin-top: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .hero-container {
    height: auto;
    padding: 15px;
  }

  .content {
    padding: 20px;
  }

  .logo-container img {
    width: 100px; /* Reducir el tamaño de la imagen */
  }

  h1 {
    font-size: 2rem; /* Reducir el tamaño del encabezado */
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  footer {
    font-size: 0.9rem;
  }
}
</style>
